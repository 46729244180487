<template>
  <validation-observer tag="form" class="container container-max-md" @submit.native.prevent ref="validation">

    <fragment>
      <div class="card-body">
          <h2>Screen Status</h2>

          <p class="text-muted mb-0">Output a report to identify the percentage of Up Time on a screen.</p>
      </div>

      <ul class="filters">

          <li>
              <div class="row">

                      <label class="col-lg-3 col-form-label">Date range</label>
                      <div class="col-lg-9">
                          <validation-provider name="date range" vid="date_range" rules="required" v-slot="{ failed }">
                            <input-date-range
                                v-model="filters.dateRange"
                                placeholder=""
                                class="form-control date-range"
                                :class="{ 'is-invalid': failed }"
                              />
                              <c-help-block />
                          </validation-provider>
                      </div>
              </div><!-- /.row -->
          </li>

          <li v-if="$isA('admin')">
              <div class="row">
                  <label class="col-lg-3 col-form-label">Media owner</label>
                  <div class="col-lg-9">
                      <validation-provider name="media owners" vid="media_owners" rules="required" v-slot="{ failed }">
                      <multiselect
                          class="multiselect-multiple"
                          multiple
                          v-model="filters.mediaOwners"
                          placeholder="Select"
                          :preselect-first="false"
                          :close-on-select="false"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                          :options="mediaOwners"
                          :class="{ 'is-invalid': failed }"
                      />
                      <c-help-block />
                    </validation-provider>
                  </div>
              </div><!-- /.row -->
          </li>

          <li>
              <div class="row">
                  <label class="col-lg-3 col-form-label">Screen tags</label>
                  <div class="col-lg-9">
                      <multiselect
                          class="multiselect-multiple"
                          multiple
                          v-model="filters.screenTags"
                          placeholder="Select"
                          :preselect-first="false"
                          :close-on-select="false"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                          :options="screenTags"
                      />
                  </div>
              </div><!-- /.row -->
          </li>
      </ul><!-- /.filters -->

      <div class="card-body border-top text-end mt-auto">
          <!-- <a @click="handleGenerateReport" class="btn btn-primary">Download</a> -->
          <c-button type="submit" variant="primary" @click="handleGenerateReport('csv')" validate class="ms-auto me-2" >
            Download CSV
          </c-button>
          <c-button type="submit" variant="primary" @click="handleGenerateReport('pdf')" validate class="ms-auto mr-2" >
            Download PDF
          </c-button>
      </div>

    </fragment>
  </validation-observer>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { http } from '@/utils'
import { get, call } from 'vuex-pathify'
import dayjs from 'dayjs'
import InputDateRange from '@/components/forms/InputDateRange'
export default {
  components: {
    InputDateRange
  },

  data () {
    return {
      filters: {
        mediaOwners: [],
        screenTags: [],
        dateRange: null,
      }
    }
  },

  async created () {
    try {
      await this.getMediaOwners()
      await this.getScreenTags()
    } catch (e) {
      toast('We encountered an error. We have been alerted and will investigate.', 'danger')
      throw e
    }
  },

  computed: {
    mediaOwners: get('lists/mediaOwners/mediaOwners'),
    screenTags: get('lists/screenTags/screenTags'),
  },

  methods: {
    getMediaOwners: call('lists/mediaOwners/getMediaOwners'),
    getScreenTags: call('lists/screenTags/getScreenTags'),

    handleGenerateReport (type) {
      const params = {
        mediaOwners: this.filters.mediaOwners.map(mediaOwner => mediaOwner.id),
        screenTags: this.filters.screenTags.map(screenTag => screenTag.id),
        startDate: this.filters.dateRange.startDate,
        endDate: this.filters.dateRange.endDate,
        type: type
      }
      return http
        .get('reports/screens', {
          responseType: 'blob',
          headers: { accept: 'application/json, text/csv' },
          params
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const filename = `BigScreenVideo-screens-${dayjs().format('YYYY-MMMM-DD')}-${dayjs().format('HH-mm')}.${type}`
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          return response
        })
    }
  }
}
</script>
